import { UriBuilder } from './utils/uri';
import { SyncError } from './utils/syncerror';
import log from './utils/logger';

import { SyncEntity, EntityServices, RemovalHandler } from './entity';
import { Network } from './interfaces/services';
import { Closeable } from './closeable';
import { Cache } from './cache';
import { nonEmptyString, validateTypes, validateTypesAsync } from '@twilio/declarative-type-validator';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

export interface InsightsServices extends EntityServices {
}

/**
 * An individual result from a LiveQuery or InstantQuery result set.
 */
export class InsightsItem {
  /**
   * @internal
   */
  constructor() {}

  /**
   * The identifier that maps to this item within the search result.
   */
  key: string;

  /**
   * The contents of the item.
   */
  value: object;
}

/**
 * A result set, i.e. a collection of items that matched a LiveQuery or InstantQuery expression.
 * Each result is a key-value pair, where each key identifies its object uniquely. These
 * results are equivalent to a set of InsightsItem-s.
 */
export interface ItemsSnapshot {
  [key: string]: object;
}

export interface InsightsQueryResponse {
  query_id: string;
  last_event_id: number;
  items?: [{
    key: string;
    data: object;
    revision: number;
  }];
}

export interface LiveQueryDescriptor {
  indexName: string;
  sid: string;
  queryExpression: string;
  queryUri: string;
  last_event_id: number;
}

export type LiveQueryCreator = (indexName: string, queryExpression: string) => Promise<LiveQuery>;

export class LiveQueryImpl extends SyncEntity {

  private readonly descriptor: LiveQueryDescriptor;
  private readonly cache: Cache<string, InsightsItem>;

  constructor(descriptor: LiveQueryDescriptor, services: InsightsServices, removalHandler: RemovalHandler, items?: any) {
    super(services, removalHandler);
    this.descriptor = descriptor;
    this.cache = new Cache<string, InsightsItem>();

    if (items) {
      items.forEach(item => {
        this.cache.store(item.key, { key: item.key, value: item.data} as InsightsItem, item.revision);
      });
    }
  }

  // public
  get sid() {
    return this.descriptor.sid;
  }

  // private extension of SyncEntity
  get uniqueName() {
    return null;
  }

  get type() {
    return LiveQueryImpl.type;
  }

  static get type() {
    return 'live_query';
  }

  get lastEventId() {
    return this.descriptor.last_event_id;
  }

  get indexName() {
    return this.descriptor.indexName;
  }

  get queryString() {
    return this.descriptor.queryExpression;
  }

  // custom private props
  get queryUri() {
    return this.descriptor.queryUri;
  }

  get liveQueryDescriptor() {
    return this.descriptor;
  }

  // dummy stub from iface
  protected onRemoved() {
  }

  public getItems(): ItemsSnapshot {
    const dataByKey = {};
    this.cache.forEach((key, item) => {
      dataByKey[key] = item.value;
    });
    return dataByKey;
  }

  /**
   * @internal
   */
  _update(message: any, isStrictlyOrdered: boolean): void {
    switch (message.type) {
      case 'live_query_item_updated':
        this.handleItemMutated(message.item_key, message.item_data, message.item_revision);
        break;
      case 'live_query_item_removed':
        this.handleItemRemoved(message.item_key, message.item_revision);
        break;
      case 'live_query_updated':
        this.handleBatchUpdate(message.items);
        break;
    }

    if (isStrictlyOrdered) {
      this._advanceLastEventId(message.last_event_id);
    }
  }

  private handleItemMutated(key: string, value: object, revision: number) {
    if (this.shouldIgnoreEvent(key, revision)) {
      log.trace(`Item ${key} update skipped, revision: ${revision}`);
    } else {
      const newItem: InsightsItem = {key, value};
      this.cache.store(key, newItem, revision);
      this.broadcastEventToListeners('itemUpdated', newItem);
    }
  }

  private handleItemRemoved(key: string, revision: number) {
    const force = (revision === null);
    if (this.shouldIgnoreEvent(key, revision)) {
      log.trace(`Item ${key} delete skipped, revision: ${revision}`);
    } else {
      this.cache.delete(key, revision, force);
      this.broadcastEventToListeners('itemRemoved', {key});
    }
  }

  private handleBatchUpdate(items) {
    // preprocess item set for easy key-based access (it's a one-time constant time operation)
    let newItems = {};
    if (items != null) {
      items.forEach(item => {
        newItems[item.key] = {
          data: item.data,
          revision: item.revision
        };
      });
    }

    // go through existing items and generate update/remove events for them
    this.cache.forEach((key, item) => {
      const newItem = newItems[key];
      if (newItem != null) {
        this.handleItemMutated(key, newItem.data, newItem.revision);
      } else {
        this.handleItemRemoved(key, null); // force deletion w/o revision
      }
      // once item is handled, remove it from incoming array
      delete newItems[key];
    });

    // once we handled all the known items, handle remaining pack
    for (let key in newItems) {
      this.handleItemMutated(key, newItems[key].data, newItems[key].revision);
    }
  }

  private shouldIgnoreEvent(key: string, eventId: number) {
    return key != null && eventId != null && this.cache.isKnown(key, eventId);
  }

  /**
   * @internal
   */
  _advanceLastEventId(eventId: number, revision?: string): void {
    // LiveQuery is not revisioned in any way, so simply ignore second param and act upon lastEventId only
    if (this.lastEventId < eventId) {
      this.descriptor.last_event_id = eventId;
    }
  }
}

export async function queryItems(params: any): Promise<InsightsQueryResponse>  {
  let { network, queryString, uri, type } = params;
  if (queryString == null) { // should not be null or undefined
    throw new SyncError(`Invalid query`, 400, 54507);
  }
  const liveQueryRequestBody: any = {
    query_string: queryString // raw query string (like `key == "value" AND key2 != "value2"`)
  };

  if (type === LiveQuery.type) {
    liveQueryRequestBody.type = type;
  }

  let response = await network.post(uri, liveQueryRequestBody, undefined, true);
  return response.body;
}

/**
 * Represents a long-running query against Flex data wherein the returned result set
 * subsequently receives pushed updates whenever new (or updated) records would match the
 * given expression. Updated results are presented row-by-row until this query is explicitly
 * closed.
 *
 * Use the {@link SyncClient.liveQuery} method to create a live query.
 */
export class LiveQuery extends Closeable {
  private readonly liveQueryImpl: LiveQueryImpl;

  // private props
  static get type() {
    return LiveQueryImpl.type;
  }

  get type() {
    return LiveQueryImpl.type;
  }

  get lastEventId(): number {
    return this.liveQueryImpl.lastEventId;
  }

  /**
   * The immutable identifier of this query object, assigned by the system.
   */
  get sid(): string {
    return this.liveQueryImpl.sid;
  }

  /**
   * @internal
   */
  constructor(liveQueryImpl: LiveQueryImpl) {
    super();
    this.liveQueryImpl = liveQueryImpl;
    this.liveQueryImpl.attach(this);
  }

  /**
   * Fired when an item has been added or updated.
   *
   * Parameters:
   * 1. {@link InsightsItem} `item` - updated item
   * @example
   * ```typescript
   * liveQuery.on('itemUpdated', (item) => {
   *   console.log(`Item ${item.key} was updated`'`);
   *   console.log('Item value:', item.value);
   * });
   * ```
   * @event
   */
  static readonly itemUpdated = 'itemUpdated';

  /**
   * Fired when an existing item has been removed.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * object `key` - the key of the removed item
   * @example
   * ```typescript
   * liveQuery.on('itemRemoved', (args) => {
   *   console.log(`Item ${args.key} was removed`);
   * });
   * ```
   * @event
   */
  static readonly itemRemoved = 'itemRemoved';

  /**
   * Closes this query instance and unsubscribes from further service events.
   * This will eventually stop the physical inflow of updates over the network, when all other instances of this query are closed as well.
   */
  public close(): void {
    super.close();
    this.liveQueryImpl.detach(this.listenerUuid);
  }

  /**
   * @return A snapshot of items matching the current query expression.
   */
  public getItems(): ItemsSnapshot {
    this.ensureNotClosed();
    return this.liveQueryImpl.getItems();
  }
}

type InstantQueryEvents = {
  searchResult: (snapshot: ItemsSnapshot) => void;
};

/**
 * Allows repetitive quick searches against a specific Flex data. Unlike a
 * LiveQuery, this result set does not subscribe to any updates and therefore receives no events
 * beyond the initial result set.
 *
 * Use the {@link SyncClient.instantQuery} method to create an Instant Query.
 */
export class InstantQuery extends ReplayEventEmitter<InstantQueryEvents> {
  private indexName: string;
  private queryUri: string;
  private readonly insightsUri: string;
  private readonly liveQueryCreator: LiveQueryCreator;
  private readonly network: Network;
  private queryExpression: string = null;
  private items = {};

  // private props
  static get type() {
    return 'instant_query';
  }

  get type() {
    return InstantQuery.type;
  }

  /**
   * @internal
   */
  constructor(params: any) {
    super();
    Object.assign(this, params);
    this.updateIndexName(params.indexName);
  }

  /**
   * Fired when a search result is ready.
   *
   * Parameters:
   * 1. {@link ItemsSnapshot} `items` - a snapshot of items matching current query expression.
   * @example
   * ```typescript
   * instantQuery.on('searchResult', (items) => {
   *    Object.entries(items).forEach(([key, value]) => {
   *      console.log('Search result item key:', key);
   *      console.log('Search result item value:', value);
   *    });
   * });
   * ```
   * @event
   */
  static readonly searchResult = 'searchResult';

  /**
   * Spawns a new search request. The result will be provided asynchronously via the {@link InstantQuery.searchResult}
   * event.
   * @param queryExpression A query expression to be executed against the given data index. For more information
   * on the syntax read {@link SyncClient.liveQuery}.
   * @return A promise that resolves when query result has been received.
   */
  @validateTypesAsync('string')
  public async search(queryExpression: string): Promise<void> {
    this.items = {};
    return queryItems({
      network: this.network,
      uri: this.queryUri,
      queryString: queryExpression,
    })
      .then((response) => {
        this.queryExpression = queryExpression;
        if (response.items) {
          response.items.forEach((item) => {
            this.items[item.key] = item.data;
          });
        }
        this.emit('searchResult', this.getItems());
      })
      .catch((err) => {
        log.error(
            `Error '${err.message}' while executing query '${queryExpression}'`
        );
        this.queryExpression = null;
        throw err;
      });
  }

  /**
   * Instantiates a LiveQuery object based on the last known query expression that was passed to the
   * {@link InstantQuery.search} method. This LiveQuery will start receiving updates with new results,
   * while current object can be still used to execute repetitive searches.
   * @return A promise which resolves when the LiveQuery object is ready.
   */
  public async subscribe(): Promise<LiveQuery> {
    if (this.queryExpression == null) { // should not be null or undefined
      return Promise.reject(new SyncError(`Invalid query`, 400, 54507));
    }

    return this.liveQueryCreator(this.indexName, this.queryExpression);
  }

  /**
   * @return A snapshot of items matching current query expression.
   */
  public getItems(): ItemsSnapshot {
    return this.items;
  }

  /**
   * Set new index name
   * @param indexName New index name to set
   */
  @validateTypes(nonEmptyString)
  public updateIndexName(indexName: string): void {
    this.indexName = indexName;
    this.queryUri = this.generateQueryUri(this.indexName);
  }

  private generateQueryUri(indexName: string) {
    return new UriBuilder(this.insightsUri)
      .pathSegment(indexName)
      .pathSegment('Items')
      .build();
  }
}

export default LiveQuery;
