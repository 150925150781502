import { v4 as uuidv4 } from 'uuid';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';
import SyncError from './utils/syncerror';
import { SubscriptionState } from './entity';
import { InsightsItem } from './livequery';
import { SyncStreamMessage } from './streams/syncstream';

export type CloseableEvents = {
  updated: (eventData: {data: any, previousData: any, isLocal: boolean}) => void;
  removed: (eventData: {previousData?: any, isLocal: boolean}) => void;
  itemUpdated: (eventData: any) => void;
  itemAdded: (eventData: any) => void;
  itemRemoved: (eventData: any) => void;
  contextUpdated: (eventData: {context: any, isLocal: boolean}) => void,
  messagePublished: (eventData: {message: SyncStreamMessage, isLocal: boolean}) => void,
  failure: (error: SyncError) => void,
  _subscriptionStateChanged: (state: SubscriptionState) => void
};

abstract class Closeable extends ReplayEventEmitter<CloseableEvents> {

  private readonly uuid: string;
  private closed: boolean;

  constructor() {
    super();
    this.closed = false;
    this.uuid = uuidv4();
  }

  get listenerUuid(): string {
    return this.uuid;
  }

  protected close() {
    this.removeAllListeners();
    this.closed = true;
  }

  protected ensureNotClosed() {
    if (this.closed) {
      throw new Error('Invalid operation on closed object');
    }
  }

}
export { Closeable };
export default Closeable;