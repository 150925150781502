import { Services, Network, Router, Storage } from './interfaces/services';
import { SyncError } from './utils/syncerror';
import { Closeable, CloseableEvents } from './closeable';

interface EntityServices {
  network: Network;
  router: Router;
  storage: Storage;
}

type RemovalHandler = (type: string, sid: string, uniqueName: string) => void;
export type SubscriptionState = 'none' | 'request_in_flight' | 'response_in_flight' | 'established';

abstract class SyncEntity {
  protected readonly services: EntityServices;
  protected readonly removalHandler: RemovalHandler;
  private subscriptionState: SubscriptionState;
  private readonly _attachedListeners: Map<string, Closeable>;

  protected constructor(services: EntityServices, removalHandler: RemovalHandler) {
    this.services = services;
    this.removalHandler = removalHandler;
    this.subscriptionState = 'none';
    this._attachedListeners = new Map<string, Closeable>();
  }

  abstract get sid(): string;

  abstract get uniqueName(): string;

  abstract get type(): string;

  abstract get lastEventId(): number;

  abstract get indexName(): string;

  abstract get queryString(): string;

  abstract _update(update: any, isStrictlyOrdered: boolean): void;

  _advanceLastEventId(eventId: number, revision?: string): void {
  }

  protected abstract onRemoved(locally: boolean): void;

  reportFailure(err: SyncError): void {
    if (err.status === 404) {
      // assume that 404 means that entity has been removed while we were away
      this.onRemoved(false);
    } else {
      this.broadcastEventToListeners('failure', err);
    }
  }

  /**
   * Subscribe to changes of data entity
   * @private
   */
  _subscribe() {
    this.services.router._subscribe(this.sid, this);
  }

  /**
   * Unsubscribe from changes of current data entity
   * @private
   */
  _unsubscribe() {
    this.services.router._unsubscribe(this.sid);
  }

  _setSubscriptionState(newState: SubscriptionState): void {
    this.subscriptionState = newState;
    this.broadcastEventToListeners('_subscriptionStateChanged', newState);
  }

  /**
   * @public
   */
  close() {
    this._unsubscribe();
    if (this.removalHandler != null) {
      this.removalHandler(this.type, this.sid, this.uniqueName);
    }
  }

  public attach(closeable: Closeable): void {
    const uuid = closeable.listenerUuid;
    const existingRecord = this._attachedListeners.get(uuid);
    if (existingRecord) {
      return;
    }

    if (!this._attachedListeners.size) {
      // the first one to arrive
      this._subscribe();
    }

    this._attachedListeners.set(uuid, closeable);
  }

  public detach(listenerUuid: string): void {
    this._attachedListeners.delete(listenerUuid);
    if (!this._attachedListeners.size) {
      // last one out, turn off lights, shut the door
      this.close(); // invokes unsubscribe and removal handler
    }
  }

  protected broadcastEventToListeners<E extends Extract<keyof CloseableEvents, string>>(eventName: E, ...args: Parameters<CloseableEvents[E]>) {
    for (let listener of this._attachedListeners.values()) {
      listener.emit(eventName, ...args);
    }
  }
}

export { Services, EntityServices, RemovalHandler, SyncEntity };
export default SyncEntity;
