import { deepClone, isPositiveInteger, validatePageSize } from './utils/sanitize';
import { UriBuilder } from './utils/uri';
import { SyncError } from './utils/syncerror';
import log from './utils/logger';

import { SyncEntity, EntityServices, RemovalHandler } from './entity';
import { SyncListItem } from './synclistitem';
import { Paginator } from './paginator';
import { Cache } from './cache';

import { Mutator } from './interfaces/mutator';
import { NamespacedMergingQueue } from './mergingqueue';
import Closeable from './closeable';
import { custom, nonNegativeInteger, objectSchema, pureObject, validateTypesAsync } from '@twilio/declarative-type-validator';

interface SyncListServices extends EntityServices {
}

interface SyncListDescriptor {
  sid: string;
  url: string;
  revision: string;
  last_event_id: number;
  links: any;
  unique_name: string;
  date_updated: Date;
  date_expires: string;
}

/**
 * List item metadata.
 */
interface SyncListItemMetadata {
  /**
   * Specifies the time-to-live in seconds after which the list item is subject to automatic deletion.
   * The value 0 means infinity.
   */
  ttl?: number;
}

/**
 * List item query options.
 */
interface SyncListItemQueryOptions {
  /**
   * Item index, which should be used as the offset.
   * If undefined, starts from the beginning or end depending on queryOptions.order.
   */
  from?: number;

  /**
   * Results page size. Default is 50.
   */
  pageSize?: number;

  /**
   * Numeric order of results. Default is "asc".
   */
  order?: 'asc' | 'desc';

  /**
   * Query limit.
   */
  limit: number;
}

class SyncListImpl extends SyncEntity {
  private descriptor: SyncListDescriptor;
  private updateMergingQueue: NamespacedMergingQueue<number, SyncListItemMetadata, SyncListItem>;
  private cache: Cache<number, SyncListItem>;
  private context: Object;
  private contextEventId: number;

  /**
   * @private
   */
  constructor(services: SyncListServices, descriptor: SyncListDescriptor, removalHandler: RemovalHandler) {
    super(services, removalHandler);

    const updateRequestReducer = (acc, input) => (typeof input.ttl === 'number') ? {ttl: input.ttl}
      : acc;
    this.updateMergingQueue = new NamespacedMergingQueue<number, SyncListItemMetadata, SyncListItem>(updateRequestReducer);
    this.cache = new Cache<number, SyncListItem>();
    this.descriptor = descriptor;
    this.descriptor.date_updated = new Date(this.descriptor.date_updated);
  }

  // private props
  get uri(): string {
    return this.descriptor.url;
  }

  get revision(): string {
    return this.descriptor.revision;
  }

  get lastEventId(): number {
    return this.descriptor.last_event_id;
  }

  get links() {
    return this.descriptor.links;
  }

  get dateExpires() {
    return this.descriptor.date_expires;
  }

  static get type() {
    return 'list';
  }

  get type() {
    return 'list';
  }

  // below properties are specific to Insights only
  get indexName(): string {
    return undefined;
  }

  get queryString(): string {
    return undefined;
  }

  // public props, documented along with class description
  get sid(): string {
    return this.descriptor.sid;
  }

  get uniqueName(): string {
    return this.descriptor.unique_name || null;
  }

  get dateUpdated(): Date {
    return this.descriptor.date_updated;
  }

  private async _addOrUpdateItemOnServer(url: string, data: Object, ifMatch: string, ttl: number) {
    const requestBody: any = {data};

    if (ttl !== undefined) {
      requestBody.ttl = ttl;
    }

    const response = await this.services.network.post(url, requestBody, ifMatch);
    response.body.data = data;
    response.body.date_updated = new Date(response.body.date_updated);

    return response.body;
  }

  public async push(value, itemMetadata?: SyncListItemMetadata) {
    let ttl = (itemMetadata || {}).ttl;
    let item = await this._addOrUpdateItemOnServer(this.links.items, value, undefined, ttl);
    let index = Number(item.index);
    this._handleItemMutated(index, item.url, item.last_event_id, item.revision, value, item.date_updated, item.date_expires, true, false);
    return this.cache.get(index);
  }

  public async set(index: number, value: Object, itemMetadataUpdates?: SyncListItemMetadata) {
    const input: SyncListItemMetadata = itemMetadataUpdates || {};
    return this.updateMergingQueue.squashAndAdd(index, input, (input) => this._updateItemUnconditionally(index, value, input.ttl));
  }

  private async _updateItemUnconditionally(index: number, data: Object, ttl?: number): Promise<SyncListItem> {
    let existingItem = await this.get(index);
    const itemDescriptor = await this._addOrUpdateItemOnServer(existingItem.uri, data, undefined, ttl);
    this._handleItemMutated(index, itemDescriptor.url, itemDescriptor.last_event_id, itemDescriptor.revision,
      itemDescriptor.data, itemDescriptor.date_updated, itemDescriptor.date_expires, false, false);
    return this.cache.get(index);
  }

  private async _updateItemWithIfMatch(index: number, mutatorFunction: Mutator, ttl?: number): Promise<SyncListItem> {
    const existingItem = await this.get(index);
    const data = mutatorFunction(deepClone(existingItem.data));
    if (data) {
      const ifMatch = existingItem.revision;
      try {
        const itemDescriptor = await this._addOrUpdateItemOnServer(existingItem.uri, data, ifMatch, ttl);
        this._handleItemMutated(index, itemDescriptor.url, itemDescriptor.last_event_id, itemDescriptor.revision,
          itemDescriptor.data, itemDescriptor.date_updated, itemDescriptor.date_expires, false, false);
        return this.cache.get(index);
      } catch (error) {
        if (error.status === 412) {
          await this._getItemFromServer(index);
          return this._updateItemWithIfMatch(index, mutatorFunction, ttl);
        } else {
          throw error;
        }
      }
    } else {
      return existingItem;
    }
  }

  public async mutate(index: number, mutator: Mutator, itemMetadataUpdates?: SyncListItemMetadata): Promise<SyncListItem> {
    const input: SyncListItemMetadata = itemMetadataUpdates || {};
    return this.updateMergingQueue.add(index, input, (input) => this._updateItemWithIfMatch(index, mutator, input.ttl));
  }

  public async update(index: number, obj: Object, itemMetadataUpdates?: SyncListItemMetadata): Promise<SyncListItem> {
    return this.mutate(index, remote => Object.assign(remote, obj), itemMetadataUpdates);
  }

  public async remove(index: number): Promise<void> {
    const item = await this.get(index);
    const previousItemData = deepClone(item.data);
    const response = await this.services.network.delete(item.uri);
    this._handleItemRemoved(index, response.body.last_event_id, previousItemData, new Date(response.body.date_updated), false);
  }

  public async get(index: number): Promise<SyncListItem> {
    let cachedItem = this.cache.get(index);
    if (cachedItem) {
      return cachedItem;
    } else {
      return this._getItemFromServer(index);
    }
  }

  private async _getItemFromServer(index: number): Promise<SyncListItem> {
    let result = await this.queryItems({index});
    if (result.items.length < 1) {
      throw new SyncError(`No item with index ${index} found`, 404, 54151);
    } else {
      return result.items[0];
    }
  }

  /**
   * Query items from the List
   * @private
   */
  protected async queryItems(arg): Promise<Paginator<SyncListItem>> {
    arg = arg || {};
    const url = new UriBuilder(this.links.items)
      .queryParam('From', arg.from)
      .queryParam('PageSize', arg.limit)
      .queryParam('Index', arg.index)
      .queryParam('PageToken', arg.pageToken)
      .queryParam('Order', arg.order)
      .build();

    let response = await this.services.network.get(url);
    let items = response.body.items.map(el => {
      el.date_updated = new Date(el.date_updated);
      let itemInCache = this.cache.get(el.index);
      if (itemInCache) {
        this._handleItemMutated(el.index, el.url, el.last_event_id, el.revision, el.data, el.date_updated, el.date_expires, false, true);
      } else {
        this.cache.store(Number(el.index), new SyncListItem({
          index: Number(el.index),
          uri: el.url,
          revision: el.revision,
          lastEventId: el.last_event_id,
          dateUpdated: el.date_updated,
          dateExpires: el.date_expires,
          data: el.data
        }), el.last_event_id);
      }
      return this.cache.get(el.index);
    });
    let meta = response.body.meta;
    return new Paginator<SyncListItem>(items
      , pageToken => this.queryItems({pageToken})
      , meta.previous_token
      , meta.next_token);
  }

  async getItems(args): Promise<Paginator<SyncListItem>> {
    args = args || {};
    validatePageSize(args.pageSize);
    args.limit = args.pageSize || args.limit || 50;
    args.order = args.order || 'asc';
    return this.queryItems(args);
  }

  /**
   * @return {Promise<Object>} Context of List
   * @private
   */
  async getContext(): Promise<Object> {
    if (!this.context) {
      let response = await this.services.network.get(this.links.context);
      // store fetched context if we have't received any newer update
      this._updateContextIfRequired(response.body.data, response.body.last_event_id);
    }
    return this.context;
  }

  public async setTtl(ttl: number): Promise<void> {
    try {
      const requestBody = {ttl};
      const response = await this.services.network.post(this.uri, requestBody);
      this.descriptor.date_expires = response.body.date_expires;
    } catch (error) {
      if (error.status === 404) {
        this.onRemoved(false);
      }
      throw error;
    }
  }

  public async setItemTtl(index: number, ttl: number): Promise<void> {
    let existingItem = await this.get(index);
    const requestBody = {ttl};
    const response = await this.services.network.post(existingItem.uri, requestBody);
    existingItem.updateDateExpires(response.body.date_expires);
  }

  async removeList() {
    await this.services.network.delete(this.uri);
    this.onRemoved(true);
  }

  protected onRemoved(locally: boolean) {
    this._unsubscribe();
    this.removalHandler(this.type, this.sid, this.uniqueName);

    this.broadcastEventToListeners('removed', {isLocal: locally});
  }

  private shouldIgnoreEvent(key: number, eventId: number) {
    return this.cache.isKnown(key, eventId);
  }

  /**
   * Handle update, which came from the server.
   * @private
   */
  _update(update, isStrictlyOrdered: boolean): void {
    const itemIndex = Number(update.item_index);
    update.date_created = new Date(update.date_created);
    switch (update.type) {
      case 'list_item_added':
      case 'list_item_updated': {
        this._handleItemMutated(
          itemIndex,
          update.item_url,
          update.id,
          update.item_revision,
          update.item_data,
          update.date_created,
          undefined, // orchestration does not include date_expires  -- @todo  it does now?
          update.type === 'list_item_added',
          true);
      }
        break;
      case 'list_item_removed': {
        this._handleItemRemoved(itemIndex, update.id, update.item_data, update.date_created, true);
      }
        break;
      case 'list_context_updated': {
        this._handleContextUpdate(update.context_data, update.id, update.date_created);
      }
        break;
      case 'list_removed': {
        this.onRemoved(false);
      }
        break;
    }

    if (isStrictlyOrdered) {
      this._advanceLastEventId(update.id, update.list_revision);
    }
  }

  _advanceLastEventId(eventId: number, revision?: string): void {
    if (this.lastEventId < eventId) {
      this.descriptor.last_event_id = eventId;
      if (revision) {
        this.descriptor.revision = revision;
      }
    }
  }

  private _updateRootDateUpdated(dateUpdated: Date) {
    if (!this.descriptor.date_updated || dateUpdated.getTime() > this.descriptor.date_updated.getTime()) {
      this.descriptor.date_updated = dateUpdated;
      this.services.storage.update(this.type, this.sid, this.uniqueName, {date_updated: dateUpdated});
    }
  }

  private _handleItemMutated(
    index: number,
    uri: string,
    lastEventId: number,
    revision: string,
    data: Object,
    dateUpdated: Date,
    dateExpires: string,
    added: boolean,
    remote: boolean
  ): void {
    if (this.shouldIgnoreEvent(index, lastEventId)) {
      log.trace(`Item ${index} update skipped, current: ${this.lastEventId}, remote: ${lastEventId}`);
      return;
    }

    this._updateRootDateUpdated(dateUpdated);
    const item = this.cache.get(index);

    if (!item) {
      const newItem = new SyncListItem({index, uri, lastEventId, revision, data, dateUpdated, dateExpires});

      this.cache.store(index, newItem, lastEventId);
      this.emitItemMutationEvent(newItem, remote, added);

      return;
    }

    const previousItemData = deepClone(item.data);
    item.update(lastEventId, revision, data, dateUpdated);
    this.cache.store(index, item, lastEventId);

    if (dateExpires !== undefined) {
      item.updateDateExpires(dateExpires);
    }

    this.emitItemMutationEvent(item, remote, false, previousItemData);
  }

  /**
   * @private
   */
  private emitItemMutationEvent(item: SyncListItem, remote: boolean, added: boolean, previousItemData: null | Object = null): void {
    const eventName = added ? 'itemAdded' : 'itemUpdated';
    const args: any = {item, isLocal: !remote};

    if (!added) {
      args.previousItemData = previousItemData;
    }

    this.broadcastEventToListeners(eventName as any, args);
  }

  /**
   * @private
   */
  private _handleItemRemoved(index: number, eventId: number, oldData: Object, dateUpdated: Date, remote: boolean): void {
    this._updateRootDateUpdated(dateUpdated);
    this.cache.delete(index, eventId);
    this.broadcastEventToListeners('itemRemoved', {index: index, isLocal: !remote, previousItemData: oldData});
  }

  /**
   * @private
   */
  private _handleContextUpdate(data: Object, eventId: number, dateUpdated: Date): void {
    this._updateRootDateUpdated(dateUpdated);
    if (this._updateContextIfRequired(data, eventId)) {
      this.broadcastEventToListeners('contextUpdated', {context: data, isLocal: false});
    }
  }

  /**
   * @private
   */
  private _updateContextIfRequired(data: Object, eventId: number): boolean {
    if (!this.contextEventId || eventId > this.contextEventId) {
      this.context = data;
      this.contextEventId = eventId;
      return true;
    } else {
      log.trace('Context update skipped, current:', this.lastEventId, ', remote:', eventId);
      return false;
    }
  }
}

/**
 * Represents a Sync list, which stores an ordered list of values.
 * Use the {@link SyncClient.list} method to obtain a reference to a Sync list.
 * Information about rate limits can be found [here](https://www.twilio.com/docs/sync/limits).
 */
class SyncList extends Closeable {
  private readonly syncListImpl: SyncListImpl;

  // private props
  get uri(): string {
    return this.syncListImpl.uri;
  }

  get revision(): string {
    return this.syncListImpl.revision;
  }

  get lastEventId(): number {
    return this.syncListImpl.lastEventId;
  }

  get links() {
    return this.syncListImpl.links;
  }

  get dateExpires() {
    return this.syncListImpl.dateExpires;
  }

  static get type() {
    return SyncListImpl.type;
  }

  get type() {
    return SyncListImpl.type;
  }

  /**
   * Unique ID of the list, immutable identifier assigned by the system.
   */
  get sid(): string {
    return this.syncListImpl.sid;
  }

  /**
   * Unique name of the list, immutable identifier that can be assigned to the list during creation.
   */
  get uniqueName(): string {
    return this.syncListImpl.uniqueName;
  }

  /**
   * Date when the list was last updated, given in UTC ISO 8601 format (e.g., '2018-04-26T15:23:19.732Z').
   */
  get dateUpdated(): Date {
    return this.syncListImpl.dateUpdated;
  }

  /**
   * @internal
   */
  constructor(syncListImpl: SyncListImpl) {
    super();
    this.syncListImpl = syncListImpl;
    this.syncListImpl.attach(this);
  }

  /**
   * Fired when a new item appears in the list, regardless of whether its creator was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * {@link SyncListItem} `item` - added item
   *     * boolean `isLocal` - equals true if the item was added by a local actor, false otherwise
   * @example
   * ```typescript
   * list.on('itemAdded', (args) => {
   *   console.log(`List item ${args.item.index} was added`);
   *   console.log('args.item.data:', args.item.data);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly itemAdded = 'itemAdded';

  /**
   * Fired when a list item is updated (not added or removed, but changed), regardless of whether the updater was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * {@link SyncListItem} `item` - updated item
   *     * boolean `isLocal` - equals true if the item was updated by a local actor, false otherwise
   *     * object `previousItemData` - contains a snapshot of the item data before the update
   * @example
   * ```typescript
   * list.on('itemUpdated', (args) => {
   *   console.log(`List item ${args.item.index} was updated`);
   *   console.log('args.item.data:', args.item.data);
   *   console.log('args.isLocal:', args.isLocal);
   *   console.log('args.previousItemData:', args.previousItemData);
   * });
   * ```
   * @event
   */
  static readonly itemUpdated = 'itemUpdated';

  /**
   * Fired when a list item is removed, regardless of whether the remover was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * number `index` - index of the removed item
   *     * boolean `isLocal` - equals true if the item was removed by a local actor, false otherwise
   *     * object `previousItemData` - contains a snapshot of the item data before the removal
   * @example
   * ```typescript
   * list.on('itemRemoved', (args) => {
   *   console.log(`List item ${args.index} was removed`);
   *   console.log('args.previousItemData:', args.previousItemData);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly itemRemoved = 'itemRemoved';

  /**
   * Fired when a list is deleted entirely, by any actor local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * boolean `isLocal` - equals true if the list was removed by a local actor, false otherwise
   * @example
   * ```typescript
   * list.on('removed', (args) => {
   *   console.log(`List ${list.sid} was removed`);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Add a new item to the list.
   * @param data Data to be added.
   * @param itemMetadata Item metadata.
   * @return The newly added item.
   * @example
   * ```typescript
   * list.push({ name: 'John Smith' }, { ttl: 86400 })
   *   .then((item) => {
   *     console.log(`List Item push() successful, item index: ${item.index}, data:`, item.data)
   *   })
   *   .catch((error) => {
   *     console.error('List Item push() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    pureObject,
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )

  public async push(data, itemMetadata?: SyncListItemMetadata): Promise<SyncListItem> {
    this.ensureNotClosed();
    return this.syncListImpl.push(data, itemMetadata);
  }

  /**
   * Assign new data to an existing item, given its index.
   * @param index Index of the item to be updated.
   * @param value New data to be assigned to an item.
   * @param itemMetadataUpdates New item metadata.
   * @return A promise with the updated item containing latest known data.
   * The promise will be rejected if the item does not exist.
   * @example
   * ```typescript
   * list.set(42, { name: 'John Smith' }, { ttl: 86400 })
   *   .then((item) => {
   *     console.log('List Item set() successful, item data:', item.data)
   *   })
   *   .catch((error) => {
   *     console.error('List Item set() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    nonNegativeInteger,
    pureObject,
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )

  public async set(index: number, value: Object, itemMetadataUpdates?: SyncListItemMetadata): Promise<SyncListItem> {
    this.ensureNotClosed();
    return this.syncListImpl.set(index, value, itemMetadataUpdates);
  }

  /**
   * Modify an existing item by applying a mutation function to it.
   * @param index Index of the item to be changed.
   * @param mutator A function that outputs a new data based on the existing data.
   * @param itemMetadataUpdates New item metadata.
   * @return Resolves with the most recent item state, the output of a successful
   * mutation or a state that prompted graceful cancellation (mutator returned `null`). This promise
   * will be rejected if the indicated item does not already exist.
   * @example
   * ```typescript
   * const mutatorFunction = (currentValue) => {
   *     currentValue.viewCount = (currentValue.viewCount || 0) + 1;
   *     return currentValue;
   * };
   * list.mutate(42, mutatorFunction, { ttl: 86400 })
   *   .then((item) => {
   *     console.log('List Item mutate() successful, new data:', item.data)
   *   })
   *   .catch((error) => {
   *     console.error('List Item mutate() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    nonNegativeInteger,
    'function',
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )

  public async mutate(index: number, mutator: Mutator, itemMetadataUpdates?: SyncListItemMetadata): Promise<SyncListItem> {
    this.ensureNotClosed();
    return this.syncListImpl.mutate(index, mutator, itemMetadataUpdates);
  }

  /**
   * Modify an existing item by appending new fields (or overwriting existing ones) with the values from the object.
   * This is equivalent to
   * ```typescript
   * list.mutate(42, (currentValue) => Object.assign(currentValue, obj));
   * ```
   * @param index Index of an item to be changed.
   * @param obj Set of fields to update.
   * @param itemMetadataUpdates New item metadata.
   * @return A promise with a modified item containing latest known data.
   * The promise will be rejected if the item was not found.
   * @example
   * ```typescript
   * // Say, the List Item (index: 42) data is `{ name: 'John Smith' }`
   * list.update(42, { age: 34 }, { ttl: 86400 })
   *   .then((item) => {
   *     // Now the List Item data is `{ name: 'John Smith', age: 34 }`
   *     console.log('List Item update() successful, new data:', item.data);
   *   })
   *   .catch((error) => {
   *     console.error('List Item update() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    nonNegativeInteger,
    pureObject,
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async update(index: number, obj: Object, itemMetadataUpdates?: SyncListItemMetadata): Promise<SyncListItem> {
    this.ensureNotClosed();
    return this.syncListImpl.update(index, obj, itemMetadataUpdates);
  }

  /**
   * Delete an item given its index.
   * @param index Index of the item to be removed.
   * @return A promise to remove the item.
   * The promise will be rejected if the item was not found.
   * @example
   * ```typescript
   * list.remove(42)
   *   .then(() => {
   *     console.log('List Item remove() successful');
   *   })
   *   .catch((error) => {
   *     console.error('List Item remove() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async remove(index: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncListImpl.remove(index);
  }

  /**
   * Retrieve an item by List index.
   * @param index Item index in the list.
   * @return A promise with the item containing latest known data.
   * The promise will be rejected if the item was not found.
   * @example
   * ```typescript
   * list.get(42)
   *   .then((item) => {
   *     console.log('List Item get() successful, item data:', item.data)
   *   })
   *   .catch((error) => {
   *     console.error('List Item get() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async get(index: number): Promise<SyncListItem> {
    this.ensureNotClosed();
    return this.syncListImpl.get(index);
  }

  /**
   * Retrieve a list context
   * @return A promise with the list's context
   * @internal
   */
  public async getContext(): Promise<Object> {
    this.ensureNotClosed();
    return this.syncListImpl.getContext();
  }

  /**
   * Query a list of items from collection.
   * Information about the query limits can be found {@link https://www.twilio.com/docs/sync/limits|here}.
   * @param queryOptions Query options.
   * @example
   * ```typescript
   * const pageHandler = (paginator) => {
   *   paginator.items.forEach((item) => {
   *     console.log(`Item ${item.index}:`, item.data);
   *   });
   *   return paginator.hasNextPage
   *     ? paginator.nextPage().then(pageHandler)
   *     : null;
   * };
   * list.getItems({ from: 0, order: 'asc' })
   *   .then(pageHandler)
   *   .catch((error) => {
   *     console.error('List getItems() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('query options', {
      from: [nonNegativeInteger, 'undefined'],
      pageSize: [custom((value) => [isPositiveInteger(value), 'a positive integer']), 'undefined']
    })
  ])
  async getItems(queryOptions: SyncListItemQueryOptions): Promise<Paginator<SyncListItem>> {
    this.ensureNotClosed();
    return this.syncListImpl.getItems(queryOptions);
  }

  /**
   * Update the time-to-live of the list.
   * @param ttl Specifies the TTL in seconds after which the list is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * list.setTtl(3600)
   *   .then(() => {
   *     console.log('List setTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('List setTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async setTtl(ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncListImpl.setTtl(ttl);
  }

  /**
   * Update the time-to-live of a list item.
   * @param index Item index.
   * @param ttl Specifies the TTL in seconds after which the list item is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * list.setItemTtl(42, 86400)
   *   .then(() => {
   *     console.log('List setItemTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('List setItemTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger, nonNegativeInteger)
  public async setItemTtl(index: number, ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncListImpl.setItemTtl(index, ttl);
  }

  /**
   * Delete this list. It will be impossible to restore it.
   * @return A promise that resolves when the list has been deleted.
   * @example
   * list.removeList()
   *   .then(() => {
   *     console.log('List removeList() successful');
   *   })
   *   .catch((error) => {
   *     console.error('List removeList() failed', error);
   *   });
   */
  async removeList(): Promise<void> {
    this.ensureNotClosed();
    return this.syncListImpl.removeList();
  }

  /**
   * Conclude work with the list instance and remove all event listeners attached to it.
   * Any subsequent operation on this object will be rejected with error.
   * Other local copies of this list will continue operating and receiving events normally.
   * @example
   * ```typescript
   * list.close();
   * ```
   */
  public close(): void {
    super.close();
    this.syncListImpl.detach(this.listenerUuid);
  }

}

export { SyncListItemMetadata, SyncListItemQueryOptions, SyncListServices, SyncListDescriptor, Mutator, SyncList, SyncListImpl };
export default SyncList;
